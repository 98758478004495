import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { Accordion, AccordionItem, Col, Container, Row } from "react-bootstrap";
import InnerHeader from "../components/layout/inner-header/InnerHeader";

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <InnerHeader title="CAREERS" subtitle='="' />
      <Container className="cat-career">
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Make waves with us </h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>
                  Join the Catalyst Tech Solutions family and become a part of a
                  globally recognized team of digital innovation.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="cat-career__vacancy-header">
              <p>Open vacancies</p>
              <h3>We're Hiring!</h3>
            </div>
            <div className="cat-career__vacancy-content">
              <Accordion>
                <AccordionItem eventKey="0">
                  <Accordion.Header>
                    ServiceNow Business Analyst
                  </Accordion.Header>
                  <Accordion.Body>
                    <section className="position-overview">
                      <h3 className="mt-3">Position Overview</h3>
                      <p>
                        We are seeking a talented and experienced ServiceNow
                        Business Analyst to join our team. In this role, you
                        will play a key part in optimizing our ServiceNow
                        platform to meet the evolving needs of our organization.
                        You will collaborate closely with stakeholders to gather
                        requirements, design solutions, and implement
                        enhancements to our ServiceNow environment.
                      </p>
                    </section>

                    <section className="key-responsibilities">
                      <h3>Key Responsibilities</h3>
                      <ul>
                        <li>
                          Gather, analyze, and document business requirements
                          for ServiceNow platform enhancements.
                        </li>
                        <li>
                          Translate business needs into technical requirements
                          and solutions.
                        </li>
                        <li>
                          Configure and customize ServiceNow applications to
                          align with business processes and requirements.
                        </li>
                        <li>
                          Collaborate with stakeholders across departments to
                          ensure alignment with business goals and objectives.
                        </li>
                        <li>
                          Identify opportunities for process improvement and
                          automation using ServiceNow platform capabilities.
                        </li>
                        <li>
                          Provide ongoing support and troubleshooting for
                          ServiceNow configurations and workflows.
                        </li>
                        <li>
                          Manage ServiceNow projects from requirements gathering
                          to deployment, ensuring successful implementation and
                          adoption.
                        </li>
                      </ul>
                    </section>

                    <section className="requirements">
                      <h3>Requirements</h3>
                      <ul>
                        <li>
                          Bachelor's degree in Computer Science, Information
                          Technology, or related field.
                        </li>
                        <li>
                          2-3 years of experience as a ServiceNow Business
                          Analyst or similar role.
                        </li>
                        <li>
                          Proficiency in ServiceNow platform, including IT
                          Service Management (ITSM), IT Operations Management
                          (ITOM), and/or IT Business Management (ITBM).
                        </li>
                        <li>
                          Strong business analysis skills with the ability to
                          gather, analyze, and document requirements.
                        </li>
                        <li>
                          Hands-on experience in configuring and customizing
                          ServiceNow applications to meet business needs.
                        </li>
                        <li>
                          Excellent communication and interpersonal skills, with
                          the ability to effectively collaborate with technical
                          and non-technical stakeholders.
                        </li>
                        <li>
                          ServiceNow certifications such as Certified
                          Implementation Specialist (CIS), Certified Application
                          Developer (CAD), or Certified System Administrator
                          (CSA) are preferred.
                        </li>
                        <li>
                          Proven ability to manage ServiceNow projects,
                          including requirements gathering, solution design,
                          implementation, and testing.
                        </li>
                      </ul>
                    </section>

                    <section className="benefits">
                      <h3>Benefits</h3>
                      <ul>
                        <li>Competitive salary</li>
                        <li>Paid time off</li>
                        <li>Professional development opportunities</li>
                        <li>Collaborative and innovative work environment</li>
                      </ul>
                    </section>

                    <section className="how-to-apply">
                      <h3>How to Apply</h3>
                      <p>
                        If you are a talented and motivated individual looking
                        to join a dynamic team, please submit your resume and
                        cover letter to{" "}
                        <a href="mailto:jcoffee@catalysttechsol.com.">
                          jcoffee@catalysttechsol.com.
                        </a>
                      </p>
                    </section>
                  </Accordion.Body>
                </AccordionItem>
                <AccordionItem eventKey="1">
                  <Accordion.Header>ServiceNow Developer</Accordion.Header>
                  <Accordion.Body>
                    <section className="position-overview">
                      <h3 className="mt-3">Position Overview</h3>
                      <p>
                        We are seeking a talented and experienced ServiceNow
                        Developer to join our team. In this role, you will be
                        responsible for designing, developing, and implementing
                        ServiceNow solutions to meet the needs of our
                        organization. You will collaborate closely with
                        stakeholders to understand requirements, design
                        solutions, and deliver high-quality ServiceNow
                        applications.
                      </p>
                    </section>

                    <section className="key-responsibilities">
                      <h3>Key Responsibilities</h3>
                      <ul>
                        <li>
                          Develop and customize ServiceNow applications
                          according to business requirements.
                        </li>
                        <li>
                          Design and implement ServiceNow workflows, forms,
                          scripts, and integrations.
                        </li>
                        <li>
                          Collaborate with stakeholders to gather requirements
                          and provide technical expertise on ServiceNow platform
                          capabilities.
                        </li>
                        <li>
                          Perform unit testing and debugging of ServiceNow
                          applications to ensure high quality and reliability.
                        </li>
                        <li>
                          Provide ongoing support and maintenance for existing
                          ServiceNow applications.
                        </li>
                        <li>
                          Stay up-to-date with ServiceNow best practices and
                          industry trends to continuously improve our ServiceNow
                          solutions.
                        </li>
                      </ul>
                    </section>

                    <section className="requirements">
                      <h3>Requirements</h3>
                      <ul>
                        <li>
                          Bachelor's degree in Computer Science, Information
                          Technology, or related field.
                        </li>
                        <li>
                          2-3 years of experience as a ServiceNow Developer or
                          similar role.
                        </li>
                        <li>
                          Proficiency in ServiceNow platform, including
                          ServiceNow scripting (JavaScript, GlideScript), UI
                          policies, Business Rules, and ServiceNow APIs.
                        </li>
                        <li>
                          Experience with ServiceNow modules such as IT Service
                          Management (ITSM), IT Operations Management (ITOM),
                          and/or IT Business Management (ITBM).
                        </li>
                        <li>
                          Strong problem-solving skills and ability to
                          troubleshoot and debug ServiceNow applications.
                        </li>
                        <li>
                          Excellent communication and interpersonal skills, with
                          the ability to effectively collaborate with technical
                          and non-technical stakeholders.
                        </li>
                        <li>
                          ServiceNow certifications such as Certified
                          Application Developer (CAD) or Certified System
                          Administrator (CSA) are preferred.
                        </li>
                      </ul>
                    </section>

                    <section className="benefits">
                      <h3>Benefits</h3>
                      <ul>
                        <li>Competitive salary</li>
                        <li>Paid time off</li>
                        <li>Professional development opportunities</li>
                        <li>Collaborative and innovative work environment</li>
                      </ul>
                    </section>

                    <section className="how-to-apply">
                      <h3>How to Apply</h3>
                      <p>
                        If you are a talented and motivated individual looking
                        to join a dynamic team, please submit your resume and
                        cover letter to{" "}
                        <a href="mailto:jcoffee@catalysttechsol.com">
                          jcoffee@catalysttechsol.com
                        </a>
                      </p>
                    </section>
                  </Accordion.Body>
                </AccordionItem>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Career;
