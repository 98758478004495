import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

interface InnerHeaderProps{
    title:string;
    subtitle?:string;
}

const InnerHeader:React.FC<InnerHeaderProps> = ({title, subtitle, ...props}) => {
  return (
    <div className='cat-inner-header'>
        <Container>
            <Row>
                <Col>
                <div className='d-flex align-items-center justify-content-center inner-wrap'>
                <h3 className="cat-inner-header__title">{title}</h3>
                <p className="cat-inner-header__sub-title">{subtitle}</p>
                </div>
  
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default InnerHeader