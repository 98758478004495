import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import { linkdnIcon, profilePic, shapeline } from "../../../assert/img";
import emailjs from "emailjs-com";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { SlLocationPin } from "react-icons/sl";
interface ContactFormProps {
  onSubmit: (formData: {
    name: string;
    email: string;
    message: string;
  }) => void;
}
const Contact: React.FC<ContactFormProps> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await emailjs.sendForm(
        "service_mogxano",
        "template_4a46zxp",
        e.target as HTMLFormElement,
        "kp8xM0-GZ5PVb5eAq"
      );
      setSubmitted(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };
  return (
    <div className="cat-contact" id="contact">
      <Container>
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Contact Us</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>Stay in the Know</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="col-lg-4 col-sm-12 mb-sm-3 mb-3">
            <div className="card">
              <div className="card-body d-flex">
                <div className="d-flex align-items-center contact-icon">
                  <FaPhone />
                </div>
                <div className="ps-3">
                  <h5 className="card-title">Phone</h5>
                  <p className="card-text"><a href="tel:+14049031168">+1 (404) 903-1168</a></p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 mb-sm-3 mb-3">
            <div className="card">
              <div className="card-body d-flex">
                <div className="d-flex align-items-center contact-icon">
                  <SlLocationPin />
                </div>
                <div className="ps-3">
                  <h5 className="card-title">Address</h5>
                  <p className="card-text">
                    500 Terry Francois St. San Francisco, CA 94158
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-sm-12 mb-sm-3 mb-3">
            <div className="card">
              <div className="card-body d-flex">
                <div className="d-flex align-items-center contact-icon">
                  <FaEnvelope />
                </div>
                <div className="ps-3">
                  <h5 className="card-title">Email</h5>
                  <p className="card-text"><a  href="mailto:jcoffee@catalysttechsol.com">jcoffee@catalysttechsol.com</a></p>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Row className="align-items-center">
          <Col lg={6} md={6} sm={12}>
            <div className="contact-map mt-3 p-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d50461.44844499284!2d-122.3871023!3d37.7703373!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x808f7fc4fe5e0af3%3A0x11c548b59491f791!2s500%20Terry%20A%20Francois%20Blvd%206th%20floor%2C%20San%20Francisco%2C%20CA%2094158-2230%2C%20USA!3m2!1d37.7703373!2d-122.3871023!5e0!3m2!1sen!2slv!4v1710365945332!5m2!1sen!2slv"
                width="100%"
                height="400"
                title="map"
                loading="lazy"
              ></iframe>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form onSubmit={handleSubmit} className="contact-form">
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <CatButton variant="primary" type="submit" size="lg">
                Submit
              </CatButton>
              {submitted && (
                <div className="success-msg">
                  <p>Form submitted successfully!</p>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
