import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import LandingArea from "../components/layout/landing-area/LandingArea";
import Services from "../components/layout/services/Services";
import About from "../components/layout/about/About";
import Contact from "../components/layout/contact/Contact";
import Clients from "../components/layout/clients/Clients";

const Home = () => {
  const handleFormSubmit = (formData: {
    name: string;
    email: string;
    message: string;
  }) => {
    // Handle form submission logic here
    console.log("Form submitted with data:", formData);
  };
  useEffect(() => {

    const scrollToElement = () => {
      const element = document.getElementById('home'); // Replace 'your-element-id' with the actual ID
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Scroll to the element after the page has loaded
    scrollToElement();
  }, [])
  return (
    <div id="home">
      <Layout >
        <LandingArea />
        <About />
        <Services />
        <Clients />
        <Contact onSubmit={handleFormSubmit} />
      </Layout>
    </div>
  );
};

export default Home;
