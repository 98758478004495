import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import {
    aws,
    microsoft,
  serviceIconItCLoud1,
  serviceIconItCLoud2,
  serviceIconItCus,
  serviceIconItData1,
  serviceIconItData2,
  serviceIconItOpp,
  serviceIconItProj,
  serviceIconItil,
  serviceIconItm,
  servicenow,
  shapelineService,
} from "../../../assert/img";

const Services = () => {
  
  return (
    
    <div className="cat-services" id="services">
           <div className="line-shape">
            <Image width="100%" src={shapelineService} alt="line-shape" />
        </div>
      <Container className="cat-services__container">
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Our Services</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>Elevate your online presence with our seamless services.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="service-wrap">
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex" >
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItm}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                IT Service Management
              </div>
              <p className="text-center mt-2">Enhance IT efficiency with our streamlined IT Service Management solutions.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItOpp}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                IT Operations Management
              </div>
              <p className="text-center mt-2">We handle everything from monitoring to maintenance, ensuring smooth functionality for your business.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItProj}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">Project Management</div>
              <p className="text-center mt-2">Unlock success with our expert Project Management services.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItil}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">ITIL Implementation</div>
              <p className="text-center mt-2">Elevate your IT service delivery with our ITIL Implementation services.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItData1}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                Data Analytics & Automation
              </div>
              <p className="text-center mt-2">Maximize insights and efficiency with our Data Analytics & Automation solutions.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItData2}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                DataOps and Data Management
              </div>
              <p className="text-center mt-2">Experience seamless data operations and effective data management with our DataOps solutions.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItCLoud1}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                Cloud Native Development
              </div>
              <p className="text-center mt-2"> We specialize in building scalable, resilient applications tailored for the cloud environment.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItCLoud2}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">Cloud Migration</div>
              <p className="text-center mt-2">We seamlessly transition your applications and data to cloud environments, ensuring minimal disruption and maximum efficiency.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} className="align-items-stretch d-flex">
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width={70}
                  src={serviceIconItCus}
                  alt="IT Service Management"
                />
              </div>
              <div className="cat-services__card-name">
                Customer Service Management
              </div>
              <p className="text-center mt-2"> We provide comprehensive tools and strategies to streamline support processes, personalize interactions, and foster lasting relationships.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="cat-exp position-relative">
    
        <Container>
          <Row>
            <Col>
              <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
                <div className="main-title">
                  <h3>OUR EXPERTISE</h3>
                </div>
            
              </div>
            </Col>
          </Row>
          <Row>
          <Col lg={4} md={4} sm={12}>
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width="100%"
                  src={microsoft}
                  alt="Microsoft"
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width="100%"
                  src={servicenow}
                  alt="Service Now"
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="cat-services__card">
              <div className="cat-services__card-icon">
                <Image
                  width="100%"
                  src={aws}
                  alt="AWS"
                />
              </div>
            </div>
          </Col>
          </Row>
          <Row>
            <Col>
       
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Services;
