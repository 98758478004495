import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { shapelineService, valueIcon } from "../../../assert/img";

const Values = () => {
  return (
    <div className="cat-values" id="values">
      <div className="line-shape">
        <Image width="100%" src={shapelineService} alt="line-shape" />
      </div>
      <Container className="cat-values__container">
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Our Values</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>Elevate your online presence with our seamless values.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="service-wrap">
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            className="align-items-stretch d-flex"
          >
            <div className="cat-values__card">
              <div className="cat-values__card-icon">
                <Image width={70} src={valueIcon} alt="IT Service Management" />
              </div>
              <div className="cat-values__card-name">
                Agility and Flexibility
              </div>
              <p className="text-center mt-2">
                We pride ourselves on being at the forefront of technological
                innovation. Our team is constantly exploring new trends and
                emerging technologies to ensure that we provide cutting-edge
                solutions to our clients. Additionally, we have a culture of
                adaptability, enabling us to quickly respond to changes in the
                industry and the needs of our clients.
              </p>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            className="align-items-stretch d-flex"
          >
            <div className="cat-values__card">
              <div className="cat-values__card-icon">
                <Image width={70}  src={valueIcon} alt="IT Service Management" />
              </div>
              <div className="cat-values__card-name">
                Customer-Centric Approach
              </div>
              <p className="text-center mt-2">
                We pride ourselves on being at the forefront of technological At
                Catalyst Tech Solutions, our foremost value is our commitment to
                putting our clients first. We understand that each client has
                unique needs and challenges, and we tailor our IT solutions to
                meet those specific requirements. Our focus is on building
                long-term relationships based on trust, reliability, and
                exceptional service.
              </p>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            className="align-items-stretch d-flex"
          >
            <div className="cat-values__card">
              <div className="cat-values__card-icon">
                <Image width={70} src={valueIcon} alt="IT Service Management" />
              </div>
              <div className="cat-values__card-name">
                Focus on Continuous Improvement
              </div>
              <p className="text-center mt-2">
                We believe in constantly striving for excellence. Through
                regular training, professional development, and feedback
                mechanisms, we are always looking for ways to improve our
                processes, enhance our skills, and deliver even greater value to
                our clients. Our dedication to continuous improvement sets us
                apart as a forward-thinking IT service provider.
              </p>
            </div>
          </Col>
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={6}
            sm={12}
            className="align-items-stretch d-flex"
          >
            <div className="cat-values__card">
              <div className="cat-values__card-icon">
                <Image width={70}  src={valueIcon} alt="IT Service Management" />
              </div>
              <div className="cat-values__card-name">
                Expertise and Experience
              </div>
              <p className="text-center mt-2">
                With years of experience in the IT industry, our team consists
                of highly skilled professionals who are experts in their
                respective fields. Whether it's cybersecurity, cloud computing,
                or software development, we have the knowledge and expertise to
                deliver top-notch solutions that exceed our clients'
                expectations.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Values;
