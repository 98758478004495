import React, { useEffect } from 'react'
import Layout from '../components/layout/Layout'
import About from '../components/layout/about/About'
import Team from '../components/layout/team/Team'
import { Col, Container, Row } from 'react-bootstrap'
import InnerHeader from '../components/layout/inner-header/InnerHeader'
import Values from '../components/layout/values/Values'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    
  }, [])
  return (
    <Layout>
      <InnerHeader title='ABOUT US' subtitle='="'/>
        <About viewMoreBtn={false}/>
             <div className="cat-about__vission">
          <div className="cat-about__vission--overlay">
            <Container>
              <Row>
                <Col>
                  <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
                    <div className="main-title">
                      <h2>Our Vission</h2>
                    </div>
                    <div className="cat-section__sub-titile">
                      <p>
                        "Catalyst Tech Solutions understands the push for
                        companies to innovate and improve mobility by making
                        data driven decisions. We are here to effectively
                        implement and maintain "
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
                    <div className="main-title">
                      <h2>Our Story</h2>
                    </div>
                    <div className="cat-section__sub-titile">
                      <p>
                        In our own business, we’ve experienced firsthand the
                        power of a catalyst. The Information Technology (IT)
                        Department at our company is responsible for maintaining
                        and improving the computer systems that keep our
                        business running. They work tirelessly to keep our
                        networks secure and improve the overall efficiency of
                        our company. 
                        <br/><br/>We’ve also been fortunate to have some
                        great employees who have been catalysts for change in
                        our company. We believe that everyone has the potential
                        to be a catalyst for change. If you have an idea that
                        you believe in, don’t be afraid to pursue it. You never
                        know where it might take you.{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Values />
        <Team/>
    </Layout>
  )
}

export default AboutUs