import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import {
  Accordion,
  AccordionItem,
  Col,
  Container,
  Image,
  Row,
} from "react-bootstrap";
import InnerHeader from "../components/layout/inner-header/InnerHeader";
import { aws, servicenow } from "../assert/img";
import CatButton from "../components/atoms/button/Button";

const Training = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <InnerHeader title="Training And Development Courses" subtitle='="' />
      <Container className="cat-training">
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>ServiceNow and AWS Training</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>
                  Unlock your potential with our comprehensive training programs
                  focused on ServiceNow and AWS. Gain valuable skills and
                  certifications to propel your career forward.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="cat-training__content">
              <Row>
                <Col lg={6} md={6} sm={6}>
                  <div className="cat-services__card">
                    <div className="cat-services__card-icon">
                      <Image width="100%" src={servicenow} alt="Service Now" />
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={6}>
                  <div className="cat-services__card">
                    <div className="cat-services__card-icon">
                      <Image width="100%" src={aws} alt="AWS" />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="ps-5">
                <section className="training-benefits mt-3">
                  <h2>Training Benefits</h2>
                  <ul>
                    <li>Learn essential skills in ServiceNow and AWS.</li>
                    <li>Gain hands-on experience with real-world projects.</li>
                    <li>Prepare for industry-recognized certifications.</li>
                    <li>Advance your career and open new opportunities.</li>
                    <li>Receive guidance and support from expert trainers.</li>
                  </ul>
                </section>

                <section className="training-topics">
                  <h2>Training Topics</h2>
                  <ul>
                    <li>ServiceNow platform overview and fundamentals</li>
                    <li>ServiceNow administration and configuration</li>
                    <li>ServiceNow application development</li>
                    <li>Introduction to AWS services</li>
                    <li>AWS cloud architecture and infrastructure</li>
                    <li>Deploying and managing applications on AWS</li>
                  </ul>
                </section>

                <section className="certifications">
                  <h2>Certifications</h2>
                  <p>
                    Participants will have the opportunity to earn
                    certifications such as:
                  </p>
                  <ul>
                    <li>ServiceNow Certified System Administrator (CSA)</li>
                    <li>AWS Certified Solutions Architect</li>
                    <li>AWS Certified Developer</li>
                    <li>And more...</li>
                  </ul>
                </section>

                <section className="how-to-register">
                  <h2>How to Register</h2>
                  <p>
                    To register for our ServiceNow and AWS training programs,
                    please send us an email to{" "}
                    <a href="mailto:jcoffee@catalysttechsol.com">
                      jcoffee@catalysttechsol.com
                    </a>
                    .
                  </p>
                  <CatButton
                    size="lg"
                    target="blank"
                    href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0SnUIe0IHIR_Iy3QSjxzct9oywXNTZtueWaa02I7jWJfxTnx-HPWnQSM1dhkh4XJgZ6zKSnLKf"
                  >
                    Register Now
                  </CatButton>
                </section>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Training;
