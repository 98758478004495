import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assert/styles/main.scss";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import Career from "./pages/Career";
import Training from "./pages/Training";


function App() {

  return (
    <div className="App">
      <Router>
      <Routes>
        <Route path="/"  element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/training" element={<Training />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
