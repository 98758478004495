import React, { useEffect } from 'react'
import Layout from '../components/layout/Layout'
import { Col, Container, Row } from 'react-bootstrap'
import InnerHeader from '../components/layout/inner-header/InnerHeader'
const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
    <InnerHeader title='BLOG' subtitle='="'/>
    <Container className='cat-blog'>
        <Row>
            <Col>
                <div className='cat-blog__no-post'>
                    <h3>Check back soon</h3>
                    <p>Once posts are published, you’ll see them here.</p>
                </div>
            </Col>
        </Row>
    </Container>
  </Layout>
  )
}

export default Blog