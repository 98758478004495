import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import {
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  linkdnIcon,
  profilePic,
  shapeline,
} from "../../../assert/img";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clients = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="cat-client mt-5" id="clients">
      {/* <div className="line-shape">
            <Image width="100%" src={shapeline} alt="line-shape" />
        </div> */}
      <Container>
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Our Clients</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>Empowering Excellence, Achieving Together.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-4">
          <Col>
            <Slider {...settings}>
              <div className="p-2">
                <Image width="85%" src={client1} alt="line-shape" />
              </div>
              <div className="p-2">
                <Image width="85%" src={client2} alt="line-shape" />
              </div>
              <div className="p-2">
                <Image width="85%" src={client3} alt="line-shape" />
              </div>
              <div className="p-2">
                <Image width="85%" src={client4} alt="line-shape" />
              </div>
              <div className="p-2">
                <Image width="85%" src={client5} alt="line-shape" />
              </div>
              <div className="p-2">
                <Image width="85%" src={client6} alt="line-shape" />
              </div>
            </Slider>
          </Col>
        </Row>
      </Container>
      <div className="cat-client__testimonials position-relative">
        <Container>
          <Row>
            <Col>
              <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
                <div className="main-title">
                  <h3>Client Testimonials</h3>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Slider {...testimonials}>
                <div className="p-2">
                  <div className="cat-client__testimonials-box">
                    <div>
                      <span className="top border"></span>
                      <p>
                        Catalyst Solutions has stepped up to the plate
                        overseeing our mixed environment of Apple/MAC and
                        Microsoft applications.
                      </p>
                      <span className="bottom border"></span>{" "}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="cat-client__testimonials-box">
                    <div>
                      <span className="top border"></span>
                      <p>
                        We can always rely on your team to answer our calls at
                        any time of the day and provide us with prompt service.
                      </p>
                      <span className="bottom border"></span>{" "}
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <div className="cat-client__testimonials-box">
                    <div>
                      <span className="top border"></span>
                      <p>
                        All you guys are awesome! Thanks for always helping me.
                        Always very nice and friendly.
                      </p>
                      <span className="bottom border"></span>{" "}
                    </div>
                  </div>
                </div>
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Clients;
