import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import { bgAbout } from "../../../assert/img";

interface aboutUsProps {
  viewMoreBtn?:boolean;
}

const About:React.FC<aboutUsProps> = ({viewMoreBtn= true}) => {
  return (
    <>
      <div className="cat-about" id="about">
        <Container>
          <Row>
            <Col>
              <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
                <div className="main-title">
                  <h2>Who We Are?</h2>
                </div>
                <div className="cat-section__sub-titile">
                  <p>Level up your IT game to the current standards.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-4">
            <Col lg={6} md={6} sm={12}>
              <Image src={bgAbout} alt="about-image" width="100%" className="about-img" />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <p className="cat-section__content">
                Catalyst Tech Solutions is an expert in providing IT Solutions and
                Services for various fields including Healthcare, Federal,
                Telecom and Fintech sectors. <br /> We offer a wide range of
                services that can help your business stay up-to-date with the
                latest technology. From managed services and cloud computing to
                data storage and security, we have you covered. We also offer
                consulting services to help you make the most of your IT
                investments.
              </p>
             { viewMoreBtn ? <CatButton href="/about" variant="primary" type="button" size="lg">
                View more
              </CatButton>: ""}
            </Col>
          </Row>
        </Container>
   
      </div>
    </>
  );
};

export default About;
