import React from "react";
import { Image } from "react-bootstrap";

import {
  shape1,
  shape2,
  shape3,
  shape4,
  exshape5,
  exshape6,
  landing1,
  landing2,
  landing3,
  landing4,
  bg1,
} from "../../../assert/img";
import CatButton from "../../atoms/button/Button";
import { SlCalender } from "react-icons/sl";
const LandingArea = () => {
  return (
    <>
      <div className="cat-slider-area p-rel mb-110 position-relative">
        <Image
          src={bg1}
          alt="landing background"
          width="100%"
          className="position-absolute bottom-0"
        />
        <div className="slider-shapes-2 d-none d-md-block">
          <div className="shape-1 shape-default">
            <Image src={shape1} alt="animated shape" />
          </div>
          <div className="shape-2 shape-default">
            <Image src={shape2} alt="animated shape" />
          </div>
          <div className="shape-3 shape-default">
            <Image src={shape3} alt="animated shape" />
          </div>
          <div className="shape-4 shape-default">
            <Image src={shape4} alt="animated shape" />
          </div>
        </div>
        <div className="cat-slider-active">
          <div className="cat-single-slider hero-gradient-2 pt-80 d-flex align-items-center cat-slider-height-2">
            <div className="container p-rel">
              <div className="extra-slider-shape d-none d-xl-block">
                <div className="shape-1">
                  <Image src={exshape5} alt="animated shape" />
                </div>
              </div>
              <div className="row pos-visible">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                  <div className="cat-slider-content">
                    <h3 className="cat-slider-content-title  fadeInUp">
                      CATALYST <br /> TECH <br /> SOLUTIONS
                    </h3>
                    <p className="cat-slider-content-desc  fadeInUp">
                      Services to maintain your digital workflows
                    </p>
                    <div className="hero-form-2 mb-12  fadeInUp">
                      {/* <form action="#">
                                                <input type="email" placeholder="Email address" id="email" />
                                                <button type="submit" className="rectangle-btn-2">subscribe </button>
                                            </form> */}
                      <CatButton
                        className="btn btn-warning"
                        size="lg"
                        target="blank"
                        href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0SnUIe0IHIR_Iy3QSjxzct9oywXNTZtueWaa02I7jWJfxTnx-HPWnQSM1dhkh4XJgZ6zKSnLKf"
                      >
                        <SlCalender className="me-3 mb-1" /> Schedule Your
                        Consultation Now
                      </CatButton>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-6 col-xl-6  col-lg-6  col-md-6">
                  <div className="slider-shapes p-rel p-rel">
                    <div className="shape-item shape-1">
                      <div className="shape-inner">
                        <Image src={landing1} alt="animated shape" />
                      </div>
                    </div>
                    <div className="shape-item shape-2">
                      <div className="shape-inner">
                        <Image src={landing3} alt="animated shape" />
                      </div>
                    </div>
                    <div className="shape-item shape-3">
                      <div className="shape-inner">
                        <Image src={landing2} alt="animated shape" />
                      </div>
                    </div>
                    <div className="shape-item shape-4">
                      <div className="shape-inner">
                        <Image src={landing4} alt="animated shape" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingArea;
