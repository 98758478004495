import React, { useEffect, useState } from "react";
import nnavLogo from "../../..//assert/img/nav-logo.webp";
import {
  Container,
  Form,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

interface HeaderProps {
  children?: any;
}

const Header: React.FC<HeaderProps> = ({ children, ...props }) => {
  const [stickyClass, setStickyClass] = useState("");
  const [resSideBarVisible, setResSideBarVisible] = useState(false);

  const navigate = useNavigate();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 80 ? setStickyClass("sticky") : setStickyClass("");
    }
  };
  const onResClick = () => {
    setResSideBarVisible(!resSideBarVisible);
  };
  const onResClose = () => {
    setResSideBarVisible(!resSideBarVisible);
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`header-transparent cat-header header-sticky ${stickyClass}`}
      >
        <Container>
          <Navbar.Brand href="/#home">
            <div className="cat-logo mr-35 d-inline-block">
              <a href="/#home" className="logo-1">
                <Image width={100} src={nnavLogo} alt="Logo" />{" "}
                {/* <span> CATALYST SOLUTIONS</span> */}
              </a>
              <a href="/#home" className="logo-2">
                <Image width={100} src={nnavLogo} alt="Logo" />
              </a>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link  href="/#home" className="nav-link">
                <HashLink className="rn-btn" to="/#home">
                  Home
                </HashLink>
              </Nav.Link>
              <Nav.Link href="/#about" className="nav-link">
                <HashLink className="rn-btn" to="/#about">
                  About
                </HashLink>
              </Nav.Link>
              <Nav.Link  href="/#services" className="nav-link">
                <HashLink className="rn-btn" to="/#services">
                  Services
                </HashLink>
              </Nav.Link>
              <Nav.Link
                href="/#clients"
                onClick={() => scrollToSection("clients")}
                className="nav-link"
              >
                Clients
              </Nav.Link>
              <Nav.Link href="/careers" className="nav-link">
                Careers
              </Nav.Link>
              <Nav.Link href="/training" className="nav-link">
                Training
              </Nav.Link>

              <Nav.Link href="/blog " className="nav-link">
                Blog
              </Nav.Link>

              <Nav.Link href="/#contact" className="nav-link">
              <HashLink className="rn-btn" to="/#contact">
              <CatButton variant="primary"  type="button">
              
                Contact Us 
              </CatButton>
              </HashLink>
              </Nav.Link>
             
        
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
