import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import CatButton from "../../atoms/button/Button";
import { linkdnIcon, profilePic, shapeline } from "../../../assert/img";
import { SlCalender } from "react-icons/sl";

const Team = () => {
  return (
    <div className="cat-team mt-5">
      <div className="line-shape">
        <Image width="100%" src={shapeline} alt="line-shape" />
      </div>
      <Container>
        <Row>
          <Col>
            <div className="cat-section__title d-flex flex-column align-items-center  justify-content-centertext-center">
              <div className="main-title">
                <h2>Our Founder</h2>
              </div>
              <div className="cat-section__sub-titile">
                <p>Empowering Excellence, Achieving Together.</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-4 ceo-card">
          <Col
            lg={6}
            md={6}
            sm={12}
            className="d-flex flex-column align-items-center justify-content-center "
          >
            <a
              href="https://www.linkedin.com/in/joshua-coffee-b29665184/?trk=people-guest_people_search-card"
              target="blank"
            >
              <div className="profile-pic">
                <Image
                  src={linkdnIcon}
                  className="linkdn-icon"
                  alt="linkdn-icon"
                />
              </div>
            </a>
            <h3 className="pt-3">Joshua Coffee</h3>
            <p>Founder & CEO</p>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <p className="cat-section__content d-flex flex-column">
              The only options are fear or mastery, I'll always choose the
              latter. Sharing Knowledge, Providing resources & collaborating is
              the centerfold to create more diversity in the space of IT.
              <span className="pt-3">
                {" "}
                Started off a Servicenow consultant, Now catering to Healthcare,
                Financial, Federal & Telecommunication industries with best in
                class IT services.
              </span>{" "}
              <span className="pt-3">
                {" "}
                Striving to provide excellent services in the field of
                information technology.
              </span>
            </p>
            <CatButton
              size="lg"
              target="blank"
              href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0SnUIe0IHIR_Iy3QSjxzct9oywXNTZtueWaa02I7jWJfxTnx-HPWnQSM1dhkh4XJgZ6zKSnLKf"
            >
              <SlCalender  className="me-3 mb-1" /> Schedule Your Consultation Now
            </CatButton>
            {/* <CatButton variant="primary" type="button" size="lg">
            View more
          </CatButton> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Team;
